import React from "react"
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import Comments from '../components/Post/Comments';

class UserInfo extends React.Component {

  constructor(props) {
 
    super(props);

    this.state = {
      s1moy:0,
      s2moy:0,
      s3moy:0,
      s4moy:0,
      s1r:0,
      s2r:0,
      s3r:0,
      s4r:0,
      s1c:0,
      s1nbr:0,
      s3nbr:0,
      cla:0,
      nbet:0,
      moy:0,
      classement:0}
  }

  
  calc(){  
    const s1moy = this.state.s1moy
    const s2moy = this.state.s2moy
    const s3moy = this.state.s3moy
    const s4moy = this.state.s4moy
    const s1nbr = this.state.s1nbr
    const s3nbr = this.state.s3nbr
    const s1r = this.state.s1r
    const s2r = this.state.s2r
    const s3r = this.state.s3r
    const s4r = this.state.s4r
    const s1c = this.state.s1c

    const cla = this.state.cla
    const nbet = this.state.nbet

    var a = 0.04;	
    var MSE = (Number(s1moy)+Number(s2moy)+Number(s3moy)+Number(s4moy))/4;

    var c = (Number(s1nbr)+Number(s3nbr));
    var s = (s1r+s2r+s3r+s4r);
    var d = s1c;
    console.log(MSE,c,s,d)

    var moy = MSE*(1-a*(c+d/2+s/4))

    var p= cla/nbet;
    var r="";
    var a10 = Math.round(0.1*nbet)/nbet;
    var a35 = Math.round(0.35*nbet)/nbet;
    var a65 = Math.round(0.65*nbet)/nbet;
    var a90 = Math.round(0.90*nbet)/nbet;

     if (p<=a10 )
          {r="A" }
        else if (p>a10  & p<=a35)
          {r="B" }
        else if (p>a35 & p<=a65)
          {r="C" }
        else if (p>a65 & p<=a90 )
          {r="D" }
        else
          {r="E"}
    this.setState({moy:moy.toFixed(3),classement:r})
    console.log(this.state);
  }

  render() {
    const siteTitle = "الباحث"
    const pageTitle = " حساب المعدل الترتيبي لمسابقة الدكتوراه 2019"
    const slug = "/حساب-المعدل-الترتيبي-لمسابقة-الدكتور"
    const self = this 
    return (
      <Layout title={`${pageTitle} - ${siteTitle}`}>
      <Sidebar />
      <Page title={pageTitle}>
          
                
                <table border="0" cellspacing="1">
                 <tbody>
                  <tr>
                    <td><font  size="4">عدد الطلبة في الدفعة </font></td>
                    <td><input type="text" value={this.state.nbet} size="11" onChange={(e)=>{self.setState({nbet: e.target.value},()=>{self.calc()}); }} /></td>
                  </tr>
                  <tr>
                    <td><font  size="4">الترتيب</font></td>
                    <td><input type="text" value={this.state.cla}  size="11" onChange={(e)=>{self.setState({cla: e.target.value},()=>{ self.calc()}); }} /></td>
                  </tr>
                  <tr bgcolor="#f2f2f2">
                    <td><font  size="4">السداسي الأول </font></td>
                    <td>
                    <table border="0" cellspacing="1">
                    <tbody>
                      <tr>
                          <td>المعدل</td>
                          <td></td>
                          <td><input type="text" value={this.state.s1moy} size="11" onChange={(e)=>{self.setState({s1moy: e.target.value},()=>{ self.calc()}); }} />	</td>
                      </tr>
                    
                      <tr>
                          <td>الانتقال بالامتحان الاستدراكي</td>
                          <td></td>
                          <td><input type="checkbox" value={this.state.s1r} id="s1r" onChange={(e)=>{self.setState({s1r: e.target.checked},()=>{ self.calc()}); }}/></td>
                      </tr>
                    
                      </tbody>
                    </table>
                    </td>
                  </tr>


                   <tr>
                    <td><font  size="4">السداسي الثاني </font></td>
                    <td>
                  <table border="0" cellspacing="1">
                  <tbody><tr>
                        <td>المعدل</td><td>
                        </td><td><input type="text" value={this.state.s2moy} size="11" onChange={(e)=>{self.setState({s2moy: e.target.value},()=>{ self.calc()}); }} />	</td>
                    </tr>
                  
                    <tr>
                        <td>الانتقال بالامتحان الاستدراكي</td><td>
                        
                        </td><td><input type="checkbox" value={this.state.s2r} id="s2r" onChange={(e)=>{self.setState({s2r: e.target.checked},()=>{ self.calc()}); }} value="1"/></td>
                    </tr>
                  <tr>
                        <td> عدد التكرارات السنة الأولى</td><td>
                        </td><td><input type="text" value={this.state.s1nbr} size="11" onChange={(e)=>{self.setState({s1nbr: e.target.value},()=>{ self.calc()}); }} />	</td>
                    </tr>
                    <tr>
                        <td>الانتقال بالديون السنة الأولى</td><td>
                        
                        </td><td><input type="checkbox" value={this.state.s1c} id="s1c" onChange={(e)=>{self.setState({s1c: e.target.checked},()=>{ self.calc()}); }} value="1"/></td>
                    </tr>
                    </tbody></table></td>
                  
                
                  </tr>
                  <tr bgcolor="#f2f2f2">
                    <td><font  size="4">السداسي الثالث </font></td>
                    <td>
                  <table border="0" cellspacing="1">
                  <tbody><tr>
                        <td>المعدل</td><td>
                        </td><td><input type="text" value={this.state.s3moy} onChange={(e)=>{self.setState({s3moy: e.target.value},()=>{ self.calc()}); }} size="11" />	</td>
                    </tr>
                    <tr>
                        <td>الانتقال بالامتحان الاستدراكي</td><td>
                        
                        </td><td><input type="checkbox" value={this.state.s3r} onChange={(e)=>{self.setState({s3r: e.target.checked},()=>{ self.calc()}); }} id="s3r" value="1"/></td>
                    </tr>
                  
                    </tbody></table></td>
                  
                
                  </tr>
                  <tr>
                    <td><font  size="4">السداسي الرابع </font></td>
                    <td>
                  <table border="0" cellspacing="1">
                  <tbody><tr>
                        <td>المعدل</td><td>
                        </td><td><input type="text" value={this.state.s4moy} onChange={(e)=>{self.setState({s4moy: e.target.value},()=>{ self.calc()}); }} size="11" />	</td>
                    </tr>
                    <tr>
                        <td>الانتقال بالامتحان الاستدراكي</td><td>
                        
                        </td><td><input type="checkbox" value={this.state.s4r} onChange={(e)=>{self.setState({s4r: e.target.checked},()=>{ self.calc()}); }} id="s4r" value="1"/></td>
                    </tr>
                  <tr>
                        <td>عدد التكرارات السنة الثانية</td><td>
                        </td><td><input type="text" value={this.state.s3nbr} onChange={(e)=>{self.setState({s3nbr: e.target.value},()=>{ self.calc()}); }} size="11" />	</td>
                    </tr>
                    </tbody></table></td>
                  
                
                  </tr>
              </tbody>
              </table>


                <div align="center">
                    <center>
                    <table>
                    <tbody><tr>
                    <td>
                    </td></tr><tr>
                      <td></td>
                      <td><h2> الصيغة الجديدة لحساب المعدل</h2></td>
                      <td></td>
                    </tr>
                    <tr>
                    </tr></tbody></table><table border="1" cellspacing="1">	
                        <tbody><tr>
                          <td><font  size="6">الصنف</font></td>
                          <td bgcolor="#99FF99" align="center"><h1><input type="text" value={this.state.classement} size="11"  readonly=""/></h1><h1></h1></td>
                          <td>&nbsp;</td>
                        </tr>
                        <tr>
                          <td><font  size="6">المعدل الترتيبي</font></td>
                          <td bgcolor="#99FF99" align="center" size="6"><input type="text" value={this.state.moy} size="11"  readonly=""/></td>
                          <td>&nbsp;</td>
                        </tr>
                    
                  </tbody></table>
                  </center>
              </div>  
              <h1> تم الحساب اعتمادا على القانون 714 الصادر بتاريخ 03 نوفمبر 2011 و الذي تم إقراره في القرار الجديد 547 الصادر بتاريخ 02/06/2016 </h1>
              <h1><a href="http://resdz.com/download/487/"> تحميل  القرار 714 </a></h1>
              <h1><a href="http://resdz.com/download/432/"> تحميل  القرار 547 </a></h1>

              <Comments postSlug={slug} postTitle={pageTitle} />
      </Page>
      </Layout>
    );
  }
}

export default UserInfo;

